(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(['video.js'], function (videojs) {
            return (root.WistiaTech = factory(videojs));
        });
    } else if (typeof module === 'object' && module.exports) {
        module.exports = root.WistiaTech = factory(require('video.js'));
    } else {
        root.WistiaTech = factory(root.videojs);
    }
})(this, function (videojs) {
    'use strict';

    const WistiaState = {
        UNSTARTED: -1,
        ENDED: 0,
        PLAYING: 1,
        PAUSED: 2,
        BUFFERING: 3,
    };

    const Tech = videojs.getComponent('Tech');

    class WistiaTech extends Tech {
        constructor(options, ready) {
            super(options, ready);
            this.setSrc(this.options_.source.src, true);
        }

        dispose() {
            this.wistiaVideo.remove();
            this.el_.parentNode.removeChild(this.el_);
        }

        createEl() {
            const protocol =
                document.location.protocol === 'file:'
                    ? 'http:'
                    : document.location.protocol;

            this.wistia = {};
            this.wistiaInfo = {};
            this.baseUrl = protocol + '//fast.wistia.com/embed/iframe/';
            this.videoOptions = WistiaTech.parseUrl(this.options_.source.src);
            this.videoId = this.videoOptions.videoId;
            this.playedId = this.options_.playerId;

            const div = videojs.dom.createEl('div', {
                id: this.videoId,
                className: this.videoOptions.classString,
                width: this.options_.width || '100%',
                height: this.options_.height || '100%',
            });

            this.wistiaScriptElement = videojs.dom.createEl('script', {
                src: protocol + '//fast.wistia.com/assets/external/E-v1.js',
            });

            const divWrapper = document.createElement('div');
            divWrapper.setAttribute('id', 'wistia-wrapper');
            divWrapper.appendChild(div);

            const divBlocker = document.createElement('div');
            divBlocker.setAttribute('class', 'vjs-iframe-blocker');
            divBlocker.setAttribute(
                'style',
                'position:absolute;top:0;left:0;width:100%;height:100%'
            );

            divWrapper.appendChild(divBlocker);
            div.insertBefore(this.wistiaScriptElement, div.firstChild);

            this.initPlayer();

            return divWrapper;
        }

        initPlayer() {
            const self = this;
            const wistiaVideoID = WistiaTech.parseUrl(
                this.options_.source.src
            ).videoId;

            self.wistiaInfo = {
                state: WistiaState.UNSTARTED,
                volume: 1,
                muted: false,
                muteVolume: 1,
                time: 0,
                duration: 0,
                buffered: 0,
                url: self.baseUrl + self.videoId,
                error: null,
            };

            this.wistiaScriptElement.onload = function () {
                self.wistiaVideo = Wistia.api(self.videoId);
                window._wq = window._wq || [];
                const videos = {};
                videos[self.videoId] = function (video) {
                    self.wistiaVideo = video;
                    self.onLoad();
                };
                window._wq.push(videos);
            };
        }

        ended() {
            return this.wistiaInfo.state === WistiaState.ENDED;
        }

        onLoad() {
            this.wistiaInfo = {
                state: WistiaState.UNSTARTED,
                volume: 1,
                muted: false,
                muteVolume: 1,
                time: 0,
                duration: this.wistiaVideo.duration(),
                buffered: 0,
                url: this.baseUrl + this.videoId,
                error: null,
            };

            const self = this;

            this.wistiaVideo.hasData(function () {
                self.onReady();
            });

            this.wistiaVideo.embedded(function () {
                const players = videojs.getPlayers();
                if (players) {
                    const player = players[self.playedId];
                    if (player && player.controls()) {
                        const videos =
                            self.player_.el_.getElementsByTagName('video');
                        if (videos.length) {
                            videos[0].style['pointerEvents'] = 'none';
                        }
                    }
                }
            }.bind(this));

            this.wistiaVideo.bind('pause', function () {
                self.onPause();
            });

            this.wistiaVideo.bind('play', function () {
                self.onPlay();
            });

            this.wistiaVideo.bind('seek', function (currentTime, lastTime) {
                self.onSeek({ seconds: currentTime });
            });

            this.wistiaVideo.bind('secondchange', function (s) {
                self.wistiaInfo.time = s;
                self.player_.trigger('timeupdate');
            });

            this.wistiaVideo.bind('end', function (t) {
                self.onFinish();
            });
        }

        onReady() {
            this.isReady_ = true;
            this.triggerReady();
            this.trigger('loadedmetadata');

            if (this.startMuted) {
                this.setMuted(true);
                this.startMuted = false;
            }

            this.wistiaInfo.duration = this.wistiaVideo.duration();
        }

        onLoadProgress(data) {
            const durationUpdate = !this.wistiaInfo.duration;
            this.wistiaInfo.duration = data.duration;
            this.wistiaInfo.buffered = data.percent;
            this.trigger('progress');
            if (durationUpdate) this.trigger('durationchange');
        }

        onPlayProgress(data) {
            this.wistiaInfo.time = data.seconds;
            this.wistiaVideo.time(this.wistiaInfo.time);
            this.trigger('timeupdate');
        }

        onPlay() {
            this.wistiaInfo.state = WistiaState.PLAYING;
            this.trigger('play');
        }

        onPause() {
            this.wistiaInfo.state = WistiaState.PAUSED;
            this.trigger('pause');
        }

        onFinish() {
            this.wistiaInfo.state = WistiaState.ENDED;
            this.trigger('ended');
        }

        onSeek(data) {
            this.trigger('seeking');
            this.wistiaInfo.time = data.seconds;
            this.wistiaVideo.time(this.wistiaInfo.time);
            this.trigger('timeupdate');
            this.trigger('seeked');
        }

        onError(error) {
            this.error = error;
            this.trigger('error');
        }

        error() {
            switch (this.errorNumber) {
                case 2:
                    return { code: 'Unable to find the video' };
                case 5:
                    return { code: 'Error while trying to play the video' };
                case 100:
                    return { code: 'Unable to find the video' };
                case 101:
                case 150:
                    return {
                        code: 'Playback on other Websites has been disabled by the video owner.',
                    };
            }
            return { code: 'Wistia unknown error (' + this.errorNumber + ')' };
        }

        playbackRate() {
            return this.suggestedRate ? this.suggestedRate : 1;
        }

        setPlaybackRate(suggestedRate) {
            if (!this.wistiaVideo) {
                return;
            }

            const d = this.wistiaVideo.playbackRate(suggestedRate);
            this.suggestedRate = suggestedRate;
            this.trigger('ratechange');
        }

        src(src) {
            if (src) {
                this.setSrc({ src: src });
            }
            return this.source;
        }

        setSrc(source) {
            if (!source || !source.src) {
                return;
            }

            this.source = source;
            this.videoOptions = WistiaTech.parseUrl(source.src);
            this.wistiaVideo.replaceWith(
                this.videoOptions.videoId,
                this.videoOptions.options
            );
        }

        supportsFullScreen() {
            return true;
        }

        load() {}

        play() {
            this.wistiaVideo.play();
        }

        pause() {
            this.wistiaVideo.pause();
        }

        paused() {
            return (
                this.wistiaInfo.state !== WistiaState.PLAYING &&
                this.wistiaInfo.state !== WistiaState.BUFFERING
            );
        }

        currentTime() {
            return this.wistiaInfo.time || 0;
        }

        setCurrentTime(seconds) {
            this.wistiaVideo.time(seconds);
            this.player_.trigger('timeupdate');
        }

        duration() {
            return this.wistiaInfo.duration || 0;
        }

        buffered() {
            return videojs.createTimeRange(
                0,
                this.wistiaInfo.buffered * this.wistiaInfo.duration || 0
            );
        }

        volume() {
            return this.wistiaInfo.muted
                ? this.wistiaInfo.muteVolume
                : this.wistiaInfo.volume;
        }

        setVolume(percentAsDecimal) {
            this.wistiaInfo.volume = percentAsDecimal;
            this.wistiaVideo.volume(percentAsDecimal);
            this.player_.trigger('volumechange');
        }

        currentSrc() {
            return this.el_.src;
        }

        muted() {
            return this.wistiaInfo.muted || false;
        }

        setMuted(muted) {
            if (muted) {
                this.wistiaInfo.muteVolume = this.wistiaInfo.volume;
                this.setVolume(0);
            } else {
                this.setVolume(this.wistiaInfo.muteVolume);
            }
            this.wistiaInfo.muted = muted;
            this.player_.trigger('volumechange');
        }
    }

    WistiaTech.isSupported = function () {
        return true;
    };

    WistiaTech.canPlaySource = function (e) {
        return e.type === 'video/wistia';
    };

    WistiaTech.parseUrl = function (url) {
        const result = {
            videoId: null,
            classes: [],
            classString: '',
            options: {},
        };

        const videoOptions = {};
        let regex =
            /^.*(wistia\.com\/)(embed\/iframe\/|medias\/)([A-z0-9]+)/;
        let match = url.match(regex);

        if (match) {
            result.videoId = match[3];
        }

        const classes = [];
        classes.push('vjs-tech');
        classes.push('wistia_embed');
        classes.push('wistia_async_' + result.videoId);

        const options = {};
        options['wmode'] = 'transparent';

        if (url) {
            let playerColorMatch = url.match(/playerColor=([#a-fA-f0-9]+)/);
            if (playerColorMatch) {
                videoOptions.playerColor = playerColorMatch[1];
            }

            let playbarMatch = url.match(/playbar=(true|false)/);
            if (playbarMatch) {
                videoOptions.playbar = playbarMatch[1];
            }

            let playButtonMatch = url.match(/playButton=(true|false)/);
            if (playButtonMatch) {
                videoOptions.playButton = playButtonMatch[1];
            }

            let smallPlayButtonMatch = url.match(
                /smallPlayButton=(true|false)/
            );
            if (smallPlayButtonMatch) {
                videoOptions.smallPlayButton = smallPlayButtonMatch[1];
            }

            let volumeControlMatch = url.match(/volumeControl=(true|false)/);
            if (volumeControlMatch) {
                videoOptions.volumeControl = volumeControlMatch[1];
            }

            let fullscreenButtonMatch = url.match(
                /fullscreenButton=(true|false)/
            );
            if (fullscreenButtonMatch) {
                videoOptions.fullscreenButton = fullscreenButtonMatch[1];
            }

            let controlsVisibleMatch = url.match(
                /controlsVisibleOnLoad=(true|false)/
            );
            if (controlsVisibleMatch) {
                videoOptions.controls = controlsVisibleMatch[1];
            }

            let chromelessMatch = url.match(/chromeless=(true|false)/);
            if (chromelessMatch) {
                videoOptions.chromeless = chromelessMatch[1];
            }

            let autoPlayMatch = url.match(/autoplay=(true|false)/);
            if (autoPlayMatch) {
                videoOptions.autoplay = autoPlayMatch[1];
            }

            let mutedMatch = url.match(/muted=(true|false)/);
            if (mutedMatch) {
                videoOptions.muted = true;
            }

            let volumeMatch = url.match(/volume=([0-9]+)/);
            if (volumeMatch) {
                videoOptions.volume = volumeMatch[1];
            }

            let endVideoBehaviorMatch = url.match(
                /endVideoBehavior=(loop|default|reset)/
            );
            if (endVideoBehaviorMatch) {
                videoOptions.endVideoBehavior = endVideoBehaviorMatch[1];
            }
        }

        let color = videoOptions.playerColor;
        if (color && color.substring(0, 1) === '#') {
            videoOptions.playerColor = color.substring(1);
        }

        if (videoOptions.chromeless) {
            options['chromeless'] = videoOptions.chromeless;
        }

        if (videoOptions.playbar) {
            options['playbar'] = videoOptions.playbar;
        }

        if (videoOptions.playButton) {
            options['playButton'] = videoOptions.playButton;
        }

        if (videoOptions.smallPlayButton) {
            options['smallPlayButton'] = videoOptions.smallPlayButton;
        }

        if (videoOptions.volumeControl) {
            options['volumeControl'] = videoOptions.volumeControl;
        }

        if (videoOptions.fullscreenButton) {
            options['fullscreenButton'] = videoOptions.fullscreenButton;
        }

        if (videoOptions.controls) {
            options['controlsVisibleOnLoad'] = videoOptions.controls;
        }

        if (videoOptions.playerColor) {
            options['playerColor'] = videoOptions.playerColor;
        }

        if (videoOptions.autoplay) {
            options['autoPlay'] = videoOptions.autoplay;
        }

		if (videoOptions.volume !== false) {
			options['volume'] = videoOptions.volume || 1;
		}
		if (videoOptions.muted) {
			options['volume'] = 0;
		}
		if (videoOptions.loop) {
			videoOptions.endVideoBehavior = 'loop';
		}
		if (videoOptions.endVideoBehavior) {
			options['endVideoBehavior'] = videoOptions.endVideoBehavior;
		}
		var keys = Object.keys(options);
		var classString = classes.join(' ') + ' ';
		for (var i = 0; i < keys.length; i++) {
			var key = keys[i];
			var value = options[key];
			classString += key + '=' + value + '&';
		}
		classString = classString.replace(/&+$/, '');
		result.classes = classes;
		result.classString = classString;
		result.options = options;
		return result;
	};
	videojs.registerTech('Wistia', WistiaTech);
});
